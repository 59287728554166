.subcategory-container {
  background-color: #eeeeee;
  overflow-y: auto;
}
.subcategory-container::-webkit-scrollbar {
  display: none;
}
.subcategory-container .subcategory-content {
  width: 95%;
  margin: 0rem auto;
  padding-block: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100vh;
}
.custom-file-input {
  display: inline-block;
  padding: 10px 10px;
  cursor: pointer;
  background-color: #b11226;
  color: #fff;
  border: 1px solid #b11226;
  border-radius: 5px;
  width: 295px;
}
.subcategory-container .subcategory-content .subcategory-header {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
}

.subcategory-container
  .subcategory-content
  .subcategory-header
  .subcategory-head {
  color: #3e5569;
}

.subcategory-container
  .subcategory-content
  .subcategory-header
  .add-subcategory {
  border-radius: 7px;
  border: none;
  padding: 10px 20px;
  background-color: #b11226;
  border-color: #b11226;
  box-shadow: 0 2px 6px #b1122661;
  font-weight: 700;
  font-size: 15px;
  color: white;
  cursor: pointer;
}

.subcategory-container .subcategory-content .subcategory-card {
  border-color: 1px solid black;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 1rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  /* overflow-x: scroll; */
}

.subcategory-container .subcategory-content .subcategory-header {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
}

.subcategory-container
  .subcategory-content
  .subcategory-header
  .subcategory-head {
  color: #3e5569;
}

.subcategory-container
  .subcategory-content
  .subcategory-header
  .add-subcategory {
  border-radius: 7px;
  border: none;
  padding: 10px 20px;
  background-color: #b11226;
  border-color: #b11226;
  box-shadow: 0 2px 6px #b1122661;
  font-weight: 700;
  font-size: 15px;
  color: white;
}

.subcategory-container .subcategory-content .subcategory-card {
  border-color: 1px solid black;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 1rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  border: none;
  border-radius: 4px;
  /* overflow-x: scroll; */
}
.subcategory-container
  .subcategory-content
  .subcategory-card
  .subcategory-card-header
  .subcategory-form-control {
  display: flex;
  align-items: flex-end;
  overflow-x: scroll;
  gap: 2rem;
}
.subcategory-container
  .subcategory-content
  .subcategory-card
  .subcategory-card-header
  .subcategory-form-control::-webkit-scrollbar {
  display: none;
}
.subcategory-container
  .subcategory-content
  .subcategory-card
  .subcategory-card-header
  .subcategory-form-control
  .subcategory-input {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 1.4rem;
}
.subcategory-container
  .subcategory-content
  .subcategory-card
  .subcategory-card-header
  .subcategory-form-control
  .subcategory-input
  .subcategory-input-cal {
  border: none;
  border-bottom: 1px solid #ccc;
  background: #fffefe;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
  font-size: 17px;
  font-weight: 400;
  width: 100%;
  width: 160px;
}
.subcategory-container
  .subcategory-content
  .subcategory-card
  .subcategory-card-header
  .subcategory-form-control
  .subcategory-input
  .subcategory-input-cal:focus {
  color: #4f5467;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
  outline: 0;
}
.subcategory-container
  .subcategory-content
  .subcategory-card
  .subcategory-card-header
  .subcategory-form-control
  .subcategory-input-search {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 0.7rem;
}
.subcategory-container
  .subcategory-content
  .subcategory-card
  .subcategory-card-header
  .subcategory-form-control
  .subcategory-input-search
  .search-input {
  border: none;
  display: block;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #4f5467;
  background-color: #fff;
  background-clip: padding-box;
  border-bottom: 1px solid #e9ecef;
  width: 160px;
}
.subcategory-container
  .subcategory-content
  .subcategory-card
  .subcategory-card-header
  .subcategory-form-control
  .subcategory-input-search
  .search-input:focus {
  color: #4f5467;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
  outline: 0;
}
.subcategory-container
  .subcategory-content
  .subcategory-card
  .subcategory-card-header
  .subcategory-form-control
  .subcategory-form-button {
  border-radius: 5px;
  margin-right: 4px;
  padding-block: 6px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 15px;
  font-weight: bold;
  background-color: #b11226;
  color: white;
  border: none;
  cursor: pointer;
}
.subcategory-container
  .subcategory-content
  .subcategory-card
  .subcategory-table {
  overflow-x: auto;
}
.subcategory-container
  .subcategory-content
  .subcategory-card
  .subcategory-table::-webkit-scrollbar {
  display: none;
}
.subcategory-container
  .subcategory-content
  .subcategory-card
  .subcategory-table
  .table-image {
  height: 40px;
  width: 40px;
  object-fit: cover;
}
.subcategory-container
  .subcategory-content
  .subcategory-card
  .subcategory-table
  .table-image
  img {
  height: 100%;
  width: 100%;
}

.subcategory-container
  .subcategory-content
  .subcategory-card
  .subcategory-table
  .badge {
  display: block;
  padding: 10px 0px;
  width: 100px;
  border-radius: 2px;
  text-align: center;
}
.subcategory-container
  .subcategory-content
  .subcategory-card
  .subcategory-table
  .badge-success {
  color: #fff;
  background-color: #28a745;
  font-size: 14px;
  font-weight: 600 !important;
}
.subcategory-container
  .subcategory-content
  .subcategory-card
  .subcategory-table
  .badge-danger {
  color: #fff;
  background-color: #b11226;
  font-size: 14px;
  font-weight: 600 !important;
}
.subcategory-container
  .subcategory-content
  .subcategory-card
  .subcategory-table
  .badge-viewMore {
  color: #fff;
  background-color: #28b779;
  font-size: 14px;
  font-weight: 600 !important;
}
.subcategory-container
  .subcategory-content
  .subcategory-card
  .subcategory-table
  .action-cell {
  display: flex;
  gap: 1.4rem;
  align-items: center;
}
.subcategory-container
  .subcategory-content
  .subcategory-card
  .subcategory-table
  .action-cell
  .edit-cell {
  background-color: #b11226;
  color: white;
  padding: 0.375rem 0.75rem;
  box-shadow: 0 2px 6px #acb5f6;
  border-radius: 2px;
}

.subcategory-table table {
  width: 100%;
  overflow-x: auto;
  border-collapse: collapse;
}

.subcategory-table th,
.subcategory-table td {
  padding: 14px 10px;
  text-align: center;
}
.subcategory-table #serial,
.subcategory-table #serial {
  min-width: 80px;
  text-align: center;
}

.subcategory-table th {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  margin-right: 10px;
  font-size: 16px;
  min-width: 75px;
}

.subcategory-table td {
  border-bottom: 1px solid #ccc;
  min-width: 155px;
  font-size: 16px;
  color: black;
  text-align: center;
}
/* .subcategory-table-image {
  height: 95px;
  width: auto;
  object-fit: cover;
} */
.subcategory-table-image img {
  height: 40px;
  width: 40px;
}
.add-subcategory-modal-overlay {
  position: fixed;
  margin-top: 70px;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease-in-out;
  z-index: 1;
}

.add-subcategory-modal-overlay .add-subcategory-modal-content-header {
  height: 3.5rem;
  background-color: #b11226;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.add-subcategory-modal-overlay .add-subcategory-modal-content {
  background: #fff;
  border-radius: 8px;
  position: relative;
  /* border: none; */
}
.add-subcategory-modal-overlay
  .add-subcategory-modal-content::-webkit-scrollbar {
  display: none;
}

.add-subcategory-modal-overlay .add-subcategory-close-button {
  position: absolute;
  top: 10px;
  right: 14px;
  font-size: 24px;
  cursor: pointer;
  color: white;
  background-color: #b11226;
}

.add-subcategory-modal-overlay .add-subcategory-modal-form {
  width: 600px;
  margin: 2rem auto;
  max-height: 450px;
  overflow-y: scroll;
  overflow-x: clip;
}
.add-subcategory-modal-form::-webkit-scrollbar {
  display: none;
}
.add-subcategory-popup-footer {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}
.add-subcategory-popup-footer .cancel-button {
  padding: 9px 20px;
  font-weight: 500;
  border-radius: 7px;
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  margin: 0px 10px;
  border: none;
  cursor: pointer;
}
.add-subcategory-popup-footer .submit-button {
  background-color: #b11226;
  border-color: #b11226;
  box-shadow: 0 2px 6px #b1122661;
  border-radius: 7px;
  padding: 9px 20px;
  font-weight: 500;
  border: none;
  color: white;
  cursor: pointer;
}

.ant-input:hover {
  border-color: black !important;
  border-right-width: 1px !important;
}
.btn-warning {
  position: relative;
  padding: 11px 16px;
  font-size: 15px;
  line-height: 1.5;
  border-radius: 3px;
  color: #fff;
  background-color: #b11226;
  border: 0;
  transition: 0.2s;
  overflow: hidden;
  /* border-radius: 40px 0px 40px 0px; */
}

.btn-warning input[type="file"] {
  cursor: pointer;
  position: absolute;
  left: 0%;
  top: 0%;
  transform: scale(3);
  opacity: 0;
}

.btn-warning:hover {
  background-color: black;
  color: white;
}
