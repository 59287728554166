.notifications-container {
  background-color: #eeeeee;
  overflow-y: scroll;
}
.notifications-container::-webkit-scrollbar {
  display: none;
}
.notifications-container .notifications-content {
  width: 95%;
  margin: 0rem auto;
  padding-block: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100vh;
}
.notifications-container .notifications-content .notifications-header {
  display: flex;
  gap: 1rem;
  align-items: baseline;
  flex-wrap: wrap;
}

.notifications-container
  .notifications-content
  .notifications-header
  .notifications-head {
  color: #3e5569;
}

.notifications-container
  .notifications-content
  .notifications-header
  .add-notifications {
  border-radius: 7px;
  border: none;
  padding: 10px 20px;
  background-color: #b11226;
  border-color: #b11226;
  box-shadow: 0 2px 6px #b1122661;
  font-weight: 700;
  font-size: 15px;
  color: white;
  cursor: pointer;
}

.notifications-container .notifications-content .notifications-card {
  border-color: 1px solid black;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 1rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  /* overflow-x: scroll; */
}

/* .notifications-container
    .notifications-content
    .notifications-header {
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .notifications-container
    .notifications-content
    .notifications-header
    .notifications-head {
    color: #3e5569;
  } */

/* .notifications-container
    .notifications-content
    .notifications-header
    .add-notifications {
    border-radius: 7px;
    border: none;
    padding: 10px 20px;
    background-color: #b11226;
    border-color: #b11226;
    box-shadow: 0 2px 6px #b1122661;
    font-weight: 700;
    font-size: 15px;
    color: white;
  }
  
  .notifications-container
    .notifications-content
    .notifications-card {
    border-color: 1px solid black;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 1rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    border: none;
    border-radius: 4px;
    /* overflow-x: scroll; }*/

.notifications-container
  .notifications-content
  .notifications-card
  .notifications-card-header
  .notifications-form-control {
  display: flex;
  align-items: flex-end;
  overflow-x: scroll;
  gap: 2rem;
}
.notifications-container
  .notifications-content
  .notifications-card
  .notifications-card-header
  .notifications-form-control::-webkit-scrollbar {
  display: none;
}
.notifications-container
  .notifications-content
  .notifications-card
  .notifications-card-header
  .notifications-form-control
  .notifications-input {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 1.4rem;
}
.notifications-container
  .notifications-content
  .notifications-card
  .notifications-card-header
  .notifications-form-control
  .notifications-input
  .notifications-input-cal {
  border: none;
  border-bottom: 1px solid #ccc;
  background: #fffefe;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
  font-size: 17px;
  font-weight: 400;
  width: 100%;
  width: 190px;
}
.notifications-container
  .notifications-content
  .notifications-card
  .notifications-card-header
  .notifications-form-control
  .notifications-input
  .notifications-input-cal:focus {
  color: #4f5467;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
  outline: 0;
}
.notifications-container
  .notifications-content
  .notifications-card
  .notifications-card-header
  .notifications-form-control
  .notifications-input-search {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 0.7rem;
}
.notifications-container
  .notifications-content
  .notifications-card
  .notifications-card-header
  .notifications-form-control
  .notifications-input-search
  .search-input {
  border-radius: 18px;
  display: block;
  width: 100%;
  padding: 6px 10px;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #4f5467;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e9ecef;
  width: 190px;
}
.notifications-container
  .notifications-content
  .notifications-card
  .notifications-card-header
  .notifications-form-control
  .notifications-input-search
  .search-input:focus {
  color: #4f5467;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
  outline: 0;
}
.notifications-container
  .notifications-content
  .notifications-card
  .notifications-card-header
  .notifications-form-control
  .notifications-form-button {
  border-radius: 22px;
  margin-right: 4px;
  padding-block: 6px;
  padding-left: 13px;
  padding-right: 13px;
  font-size: 14px;
  background-color: #b11226;
  color: white;
  border: none;
  cursor: pointer;
}
.notifications-container
  .notifications-content
  .notifications-card
  .notifications-table {
  overflow-x: auto;
}
.notifications-container
  .notifications-content
  .notifications-card
  .notifications-table::-webkit-scrollbar {
  display: none;
}
.notifications-container
  .notifications-content
  .notifications-card
  .notifications-table
  .table-image {
  height: 90px;
  width: 90px;
  object-fit: cover;
}
.notifications-container
  .notifications-content
  .notifications-card
  .notifications-table
  .table-image
  img {
  height: 100%;
  width: 100%;
}

.notifications-container
  .notifications-content
  .notifications-card
  .notifications-table
  .badge {
  padding: 10px 24px;
  border-radius: 2px;
  text-align: center;
}
.notifications-container
  .notifications-content
  .notifications-card
  .notifications-table
  .badge-success {
  color: #fff;
  background-color: #28a745;
  font-size: 14px;
  font-weight: 600 !important;
}
.notifications-container
  .notifications-content
  .notifications-card
  .notifications-table
  .badge-viewMore {
  color: #fff;
  background-color: #28b779;
  font-size: 14px;
  font-weight: 600 !important;
}
.notifications-container
  .notifications-content
  .notifications-card
  .notifications-table
  .action-cell {
  display: flex;
  gap: 1.4rem;
  align-items: center;
}

.notifications-container
  .notifications-content
  .notifications-card
  .notifications-table
  .action-cell
  .edit-cell {
  background-color: #b11226;
  color: white;
  padding: 0.375rem 0.75rem;
  box-shadow: 0 2px 6px #acb5f6;
  border-radius: 2px;
}

.notifications-table {
  overflow-x: scroll;
}

.notifications-table table {
  width: 100%;
  overflow-x: scroll;
  border-collapse: collapse;
}

.notifications-table th,
.notifications-table td {
  padding: 14px 10px;
  text-align: center;
}

.notifications-table #serial,
.notifications-table #serial {
  min-width: 80px;
  text-align: center;
}

.notifications-table th {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  margin-right: 10px;
  min-width: 155px;
  font-size: 16px;
}

.notifications-table td {
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  color: black;
}
.notifications-table-image {
  height: 95px;
  width: auto;
  object-fit: cover;
}
.notifications-table-image img {
  height: 100%;
  width: 100%;
}
.add-notifications-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease-in-out;
  z-index: 1;
}

.add-notifications-modal-overlay .add-notifications-modal-content-header {
  height: 3.5rem;
  background-color: #b11226;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.add-notifications-modal-overlay .add-notifications-modal-content {
  background: #fff;
  border-radius: 8px;
  position: relative;
}
.add-notifications-modal-overlay
  .add-notifications-modal-content::-webkit-scrollbar {
  display: none;
}

.add-notifications-modal-overlay .add-notifications-close-button {
  position: absolute;
  top: 10px;
  right: 14px;
  font-size: 24px;
  cursor: pointer;
  color: white;
  background-color: #b11226;
}

.add-notifications-modal-overlay .add-notifications-modal-form {
  width: auto;
  margin: 2rem auto;
  max-height: 450px;
  overflow-y: scroll;
  overflow-x: clip;
}
.add-notifications-modal-overlay
  .add-notifications-modal-form::-webkit-scrollbar {
  display: none;
}
.add-notifications-modal-overlay .add-notifications-popup-footer {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}
.add-notifications-modal-overlay
  .add-notifications-popup-footer
  .cancel-button {
  padding: 9px 20px;
  font-weight: 500;
  border-radius: 7px;
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  margin: 0px 10px;
  border: none;
  cursor: pointer;
}
.add-notifications-modal-overlay
  .add-notifications-popup-footer
  .submit-button {
  background-color: #b11226;
  border-color: #b11226;
  box-shadow: 0 2px 6px #b1122661;
  border-radius: 7px;
  padding: 9px 20px;
  font-weight: 500;
  border: none;
  color: white;
  cursor: pointer;
}

.ant-input:hover {
  border-color: black !important;
  border-right-width: 1px !important;
}
.add-notifications-modal-overlay .person-select {
  max-height: 50px;
  overflow-y: scroll;
}
.add-notifications-modal-overlay .person-select::-webkit-scrollbar {
  display: none;
}
