.reward-manage-container {
  background-color: #eeeeee;
}
.reward-manage-container .reward-manage-content {
  width: 95%;
  margin: 0rem auto;
  padding-block: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.reward-manage-container .reward-manage-content .reward-manage-header {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
}

.reward-manage-container
  .reward-manage-content
  .reward-manage-header
  .reward-manage-head {
  color: #3e5569;
  padding-top: 10px;
  font-weight: bold;
}

.reward-manage-container
  .reward-manage-content
  .reward-manage-header
  .add-reward-manage {
  border-radius: 7px;
  border: none;
  padding: 13px 25px;
  background-color: #b11226;
  border-color: #b11226;
  box-shadow: 0 2px 6px #b1122661;
  font-weight: 700;
  font-size: 16px;
  color: white;
}

.reward-manage-container .reward-manage-content .reward-manage-card {
  border-color: 1px solid black;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 15px 10px;
}
.reward-manage-container
  .reward-manage-content
  .reward-manage-card
  .reward-manage-card-header
  .reward-manage-form-control {
  display: flex;
  align-items: flex-end;
  overflow-x: scroll;
  gap: 3rem;
  /* position: fixed; */
}
.reward-manage-container
  .reward-manage-content
  .reward-manage-card
  .reward-manage-card-header
  .reward-manage-form-control::-webkit-scrollbar {
  display: none;
}
.reward-manage-container
  .reward-manage-content
  .reward-manage-card
  .reward-manage-card-header
  .reward-manage-form-control
  .reward-manage-input {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.reward-man {
  gap: 20px;
}
.reward-man1 {
  gap: 12px;
}

.reward-manage-container
  .reward-manage-content
  .reward-manage-card
  .reward-manage-card-header
  .reward-manage-form-control
  .reward-manage-input
  .reward-manage-input-cal {
  border: none;
  border-bottom: 1px solid #ccc;
  background: #fffefe;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
  font-size: 17px;
  font-weight: 400;
  width: 170px;
}
.reward-manage-container
  .reward-manage-content
  .reward-manage-card
  .reward-manage-card-header
  .reward-manage-form-control
  .reward-manage-input
  .reward-manage-input-cal:focus {
  color: #4f5467;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
  outline: 0;
}
.reward-manage-container
  .reward-manage-content
  .reward-manage-card
  .reward-manage-card-header
  .reward-manage-form-control
  .reward-manage-input
  .search-input {
  border-radius: 18px;
  display: block;
  padding: 6px 10px;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #4f5467;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e9ecef;
  width: 200px;
}
.reward-manage-container
  .reward-manage-content
  .reward-manage-card
  .reward-manage-card-header
  .reward-manage-form-control
  .reward-manage-input
  .search-input:focus {
  color: #4f5467;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
  outline: 0;
}
.reward-manage-container
  .reward-manage-content
  .reward-manage-card
  .reward-manage-card-header
  .reward-manage-form-control
  .reward-manage-form-button {
  border-radius: 5px;
  margin-right: 4px;
  padding-block: 6px;

  font-size: 20px;
  font-weight: bold;
  background-color: #b11226;
  color: white;
  border: none;
  cursor: pointer;
}
.reward-manage-container
  .reward-manage-content
  .reward-manage-card
  .reward-manage-card-header
  .reward-manage-form-control
  .reward-manage-form-excel {
  border-radius: 5px;
  margin-right: 4px;
  padding-block: 6px;
  font-size: 20px;
  font-weight: bold;
  background-color: #358053;
  color: white;
  border: none;
  cursor: pointer;
}
.reward-manage-container
  .reward-manage-content
  .reward-manage-card
  .reward-manage-table
  .table-image {
  height: 40px;
  width: 40px;
  object-fit: cover;
}
td {
  text-align: center;
}
.reward-manage-container
  .reward-manage-content
  .reward-manage-card
  .reward-manage-table
  .table-image
  img {
  height: 100%;
  width: 100%;
}

.reward-manage-container
  .reward-manage-content
  .reward-manage-card
  .reward-manage-table
  .badge1 {
  min-width: 100px;
  min-height: 40px;
  padding: 15px 24px;
  border-radius: 2px;
  text-align: center;
}
.reward-manage-container
  .reward-manage-content
  .reward-manage-card
  .reward-manage-table
  .badge2 {
  min-width: 100px;
  min-height: 40px;
  padding: 15px 24px;
  border-radius: 2px;
  text-align: center;
}
.reward-manage-container
  .reward-manage-content
  .reward-manage-card
  .reward-manage-table
  .badge {
  min-width: 100px;
  min-height: 40px;
  padding: 8px 28px;
  border-radius: 2px;
  text-align: center;
}
.reward-manage-container
  .reward-manage-content
  .reward-manage-card
  .reward-manage-table
  .badge-success {
  color: #fff;
  background-color: #28a745;
  font-size: 14px;
  font-weight: 600 !important;
}
.reward-manage-container
  .reward-manage-content
  .reward-manage-card
  .reward-manage-table
  .badge-danger {
  color: #fff;
  background-color: #b11226;
  font-size: 14px;
  font-weight: 600 !important;
}
.reward-manage-container
  .reward-manage-content
  .reward-manage-card
  .reward-manage-table
  .badge-success1 {
  color: #fff;
  background-color: #28b779;
  font-size: 14px;
  font-weight: 600 !important;
}
.reward-manage-container
  .reward-manage-content
  .reward-manage-card
  .reward-manage-table
  .badge-success2 {
  color: #fff;
  background-color: #28b779;
  font-size: 14px;
  font-weight: 600 !important;
}
.reward-manage-container
  .reward-manage-content
  .reward-manage-card
  .reward-manage-table
  .action-cell {
  display: flex;
  gap: 1.4rem;
  align-items: center;
}
.reward-manage-container
  .reward-manage-content
  .reward-manage-card
  .reward-manage-table
  .action-cell
  .edit-cell {
  background-color: #b11226;
  color: white;
  padding: 0.375rem 0.75rem;
  box-shadow: 0 2px 6px #acb5f6;
  border-radius: 2px;
}
.reward-manage-table {
  overflow-x: auto;
}
.reward-manage-table table {
  margin-top: 2rem;
  width: 100%;
  border-collapse: collapse;
  overflow-x: auto;
}

.reward-manage-table th,
.reward-manage-table td {
  padding: 10px;
  text-align: left;
}
.reward-manage-table th {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  font-size: 16px;
}

.reward-manage-table .th1 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 60px;
}
.reward-manage-table .th2 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 130px;
}
.reward-manage-table .th3 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 50px;
}
.reward-manage-table .th4 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 150px;
}
.reward-manage-table .th5 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 160px;
}
.reward-manage-table .th6 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 60px;
}
.reward-manage-table .th7 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 120px;
}
.reward-manage-table .th8 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 100px;
}
.reward-manage-table .th9 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 130px;
}

.reward-manage-table .th10 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 60px;
}

.reward-manage-table .th11 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 130px;
}
.reward-manage-table .th12 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 160px;
}
.reward-manage-table .th13 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 150px;
}

.reward-manage-table td {
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  color: black;
}

.add-reward-manage-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease-in-out;
  z-index: 1;
}

.add-reward-manage-modal-overlay .add-reward-manage-modal-content-header {
  height: 3.5rem;
  background-color: #b11226;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.add-reward-manage-modal-overlay .add-reward-manage-modal-contents {
  background: #fff;
  border-radius: 8px;
  position: relative;
}

.ant-form-vertical .ant-row {
  flex-direction: row !important;
}
.add-reward-manage-modal-overlay .add-reward-manage-close-button {
  position: absolute;
  top: 10px;
  right: 14px;
  font-size: 24px;
  cursor: pointer;
  color: white;
  background-color: #b11226;
}

/* Add this CSS to your stylesheets */
/* Add this CSS to your stylesheets */
.ant-select-selection-item.ant-select-selection-item-selected,
.ant-select-item-option.ant-select-item-option-active,
.ant-select-item-option.ant-select-item-option-selected {
  background-color: white !important;
  color: black; /* Set the text color for better visibility */
}

#rewardImage {
  padding: 10px;
}

/* Styles for the custom-styled button */
.custom-file-input {
  display: inline-block;
  padding: 10px 15px;
  cursor: pointer;
  background-color: #b11226;
  color: #fff;
  border: 1px solid #b11226;
  border-radius: 5px;
}
.parent-select
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  /* padding-top: 8px !important; */
}

.add-reward-manage-modal-overlay .add-reward-manage-modal-forms {
  width: auto;
  padding: 2rem 2rem;
  margin: 0px;
}
.add-reward-manage-modal-overlay .add-reward-manage-popup-footer {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}
.add-reward-manage-modal-overlay
  .add-reward-manage-popup-footer
  .cancel-button {
  padding: 9px 20px;
  font-weight: 500;
  border-radius: 7px;
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  margin: 0px 10px;
  border: none;
  cursor: pointer;
}
.add-reward-manage-modal-overlay
  .add-reward-manage-popup-footer
  .submit-button {
  background-color: #b11226;
  border-color: #b11226;
  box-shadow: 0 2px 6px #b1122661;
  border-radius: 7px;
  padding: 9px 20px;
  font-weight: 500;
  border: none;
  color: white;
  cursor: pointer;
}
.row-gap {
  gap: 0px;
}

.edit-category-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease-in-out;
  z-index: 1;
}

.edit-category-modal-overlay .edit-category-modal-content-header {
  height: 3.5rem;
  background-color: #b11226;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
/* .ant-form-item-control-input-content {
  display: flex;
} */

.edit-category-modal-overlay .edit-category-modal-content {
  background: #fff;
  border-radius: 8px;
  position: relative;
}

.edit-category-modal-overlay .edit-category-close-button {
  position: absolute;
  top: 10px;
  right: 14px;
  font-size: 24px;
  cursor: pointer;
  color: white;
  background-color: #b11226;
}

.edit-category-modal-overlay .edit-category-modal-form {
  width: auto;
  margin: 2rem auto;
}
.edit-category-modal-overlay .edit-category-popup-footer {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}
.edit-category-modal-overlay .edit-category-popup-footer .cancel-button {
  padding: 9px 20px;
  font-weight: 500;
  border-radius: 7px;
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  margin: 0px 10px;
  border: none;
  cursor: pointer;
}
.edit-category-modal-overlay .edit-category-popup-footer .submit-button {
  background-color: #b11226;
  border-color: #b11226;
  box-shadow: 0 2px 6px #b1122661;
  border-radius: 7px;
  padding: 9px 20px;
  font-weight: 500;
  border: none;
  color: white;
  cursor: pointer;
}
.ant-form-item-label > label {
  font-weight: bold;
}
.btn-warning {
  position: relative;
  padding: 11px 16px;
  font-size: 15px;
  line-height: 1.5;
  border-radius: 3px;
  color: #fff;
  background-color: #b11226;
  border: 0;
  transition: 0.2s;
  overflow: hidden;
  /* border-radius: 40px 0px 40px 0px; */
}

.btn-warning input[type="file"] {
  cursor: pointer;
  position: absolute;
  left: 0%;
  top: 0%;
  transform: scale(3);
  opacity: 0;
}

.btn-warning:hover {
  background-color: black;
  color: white;
}
.select-extra .ant-form-item-explain-error {
  margin-top: 10px;
}
