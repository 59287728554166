.user-manage-container {
  background-color: #eeeeee;
}

.user-manage-container .user-manage-content {
  width: 95%;
  margin: 0rem auto;
  padding-block: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.user-manage-container .user-manage-content .customer-manage-header {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
}

.user-manage-container
  .user-manage-content
  .customer-manage-header
  .customer-manage-head {
  color: #3e5569;
  padding-top: 10px;
  font-weight: bold;
}

.user-manage-container
  .user-manage-content
  .customer-manage-header
  .add-customer-manage {
  border-radius: 7px;
  border: none;
  padding: 13px 25px;
  background-color: #b11226;
  border-color: #b11226;
  box-shadow: 0 2px 6px #b1122661;
  font-weight: 700;
  font-size: 16px;
  color: white;
}

.user-manage-container .user-manage-content .customer-manage-card {
  border-color: 1px solid black;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 15px 10px;
}

.user-manage-container
  .user-manage-content
  .customer-manage-card
  .quote-manage-card-header
  .quote-manage-form-control {
  display: flex;
  align-items: flex-end;
  overflow-x: scroll;
  gap: 3rem;
}

.user-manage-container
  .user-manage-content
  .customer-manage-card
  .quote-manage-card-header
  .quote-manage-form-control::-webkit-scrollbar {
  display: none;
}

.user-manage-container
  .user-manage-content
  .customer-manage-card
  .quote-manage-card-header
  .quote-manage-form-control
  .quote-manage-input {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.quote-man {
  gap: 20px;
}

.customer-man1 {
  gap: 12px;
}

.user-manage-container
  .user-manage-content
  .customer-manage-card
  .quote-manage-card-header
  .quote-manage-form-control
  .quote-manage-input
  .quote-manage-input-cal {
  border: none;
  border-bottom: 1px solid #ccc;
  background: #fffefe;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
  font-size: 17px;
  font-weight: 400;
  width: 170px;
}
.user-manage-container
  .user-manage-content
  .customer-manage-card
  .quote-manage-card-header
  .quote-manage-form-control
  .quote-manage-input
  .quote-manage-input-cal:focus {
  color: #4f5467;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
  outline: 0;
}
.user-manage-container
  .user-manage-content
  .customer-manage-card
  .quote-manage-card-header
  .quote-manage-form-control
  .quote-manage-input
  .search-input {
  border: none;
  display: block;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #4f5467;
  background-color: #fff;
  background-clip: padding-box;
  border-bottom: 1px solid #e9ecef;
  width: 200px;
}

.user-manage-container
  .user-manage-content
  .customer-manage-card
  .quote-manage-card-header
  .quote-manage-form-control
  .quote-manage-input
  .search-input:focus {
  color: #4f5467;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
  outline: 0;
}
.user-manage-container
  .user-manage-content
  .customer-manage-card
  .quote-manage-card-header
  .quote-manage-form-control
  .customer-manage-form-button {
  border-radius: 5px;
  margin-right: 4px;
  padding-block: 6px;
  font-size: 20px;
  font-weight: bold;
  background-color: #b11226;
  color: white;
  border: none;
  cursor: pointer;
}
.user-manage-container
  .user-manage-content
  .customer-manage-card
  .quote-manage-card-header
  .quote-manage-form-control
  .quote-manage-form-excel {
  border-radius: 5px;
  margin-right: 4px;
  padding-block: 6px;
  font-size: 20px;
  font-weight: bold;
  background-color: #358053;
  color: white;
  border: none;
  cursor: pointer;
}
.user-manage-container
  .user-manage-content
  .customer-manage-card
  .users-manage-table
  .table-image {
  height: 40px;
  width: 40px;
  object-fit: cover;
}
td {
  text-align: center;
}
.user-manage-container
  .user-manage-content
  .customer-manage-card
  .users-manage-table
  .table-image
  img {
  height: 100%;
  width: 100%;
}

.user-manage-container
  .user-manage-content
  .customer-manage-card
  .users-manage-table
  .badge1 {
  min-width: 100px;
  min-height: 40px;
  padding: 10px 20px;
  border-radius: 2px;
  text-align: center;
}
.user-manage-container
  .user-manage-content
  .customer-manage-card
  .users-manage-table
  .badge2 {
  min-width: 100px;
  min-height: 40px;
  padding: 10px 20px;
  border-radius: 2px;
  text-align: center;
}
.user-manage-container
  .user-manage-content
  .customer-manage-card
  .users-manage-table
  .badge {
  min-width: 100px;
  min-height: 40px;
  padding: 10px 20px;
  border-radius: 2px;
  text-align: center;
}
.user-manage-container
  .user-manage-content
  .customer-manage-card
  .users-manage-table
  .badge-success {
  color: #fff;
  background-color: #28a745;
  font-size: 14px;
  font-weight: 600 !important;
}
.user-manage-container
  .user-manage-content
  .customer-manage-card
  .users-manage-table
  .badge-danger {
  color: #fff;
  background-color: #b11226;
  font-size: 14px;
  font-weight: 600 !important;
}
.user-manage-container
  .user-manage-content
  .customer-manage-card
  .users-manage-table
  .badge-success1 {
  color: #fff;
  background-color: #28b779;
  font-size: 14px;
  font-weight: 600 !important;
}

.users-manage-table .badge-success2 {
  color: #fff;
  background-color: #28b779;
  font-size: 14px;
  font-weight: 600 !important;
}
.user-manage-container
  .user-manage-content
  .customer-manage-card
  .users-manage-table
  .action-cell {
  display: flex;
  gap: 1.4rem;
  align-items: center;
}
.user-manage-container
  .user-manage-content
  .customer-manage-card
  .users-manage-table
  .action-cell
  .edit-cell {
  background-color: #b11226;
  color: white;
  padding: 0.375rem 0.75rem;
  box-shadow: 0 2px 6px #acb5f6;
  border-radius: 2px;
}
.users-manage-table {
  overflow-x: auto;
}
.users-manage-table table {
  margin-top: 2rem;
  width: 100%;
  border-collapse: collapse;
  overflow-x: auto;
}

.users-manage-table th,
.users-manage-table td {
  padding: 10px;
  text-align: left;
}

.users-manage-table th {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  font-size: 16px;
}

.users-manage-table .th1 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 30px;
}
.users-manage-table .th2 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 110px;
}
.users-manage-table .th3 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 200px;
}
.users-manage-table .th4 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 140px;
}
.users-manage-table .th5 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 100px;
}
.users-manage-table .th6 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 140px;
}
.users-manage-table .th7 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 120px;
}

.users-manage-table .th8 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 120px;
}

.users-manage-table .th9 {
  border-bottom: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.54);
  min-width: 130px;
}

.users-manage-table td {
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  color: black;
}

.ant-form-vertical .ant-row {
  flex-direction: row !important;
}

.ant-select-selection-item.ant-select-selection-item-selected,
.ant-select-item-option.ant-select-item-option-active,
.ant-select-item-option.ant-select-item-option-selected {
  background-color: white !important;
  color: black;
}

#customerImage {
  padding: 10px;
}

.custom-file-input {
  display: inline-block;
  padding: 10px 15px;
  cursor: pointer;
  background-color: #b11226;
  color: #fff;
  border: 1px solid #b11226;
  border-radius: 5px;
}

.row-gap {
  gap: 0px;
}

.ant-form-item-label > label {
  font-weight: bold;
}
.btn-warning {
  position: relative;
  padding: 11px 16px;
  font-size: 15px;
  line-height: 1.5;
  border-radius: 3px;
  color: #fff;
  background-color: #b11226;
  border: 0;
  transition: 0.2s;
  overflow: hidden;
  /* border-radius: 40px 0px 40px 0px; */
}

.btn-warning input[type="file"] {
  cursor: pointer;
  position: absolute;
  left: 0%;
  top: 0%;
  transform: scale(3);
  opacity: 0;
}

.btn-warning:hover {
  background-color: black;
  color: white;
}
.select-extra .ant-form-item-explain-error {
  margin-top: 10px;
}
